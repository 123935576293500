import { Routes, Route, Link, useParams } from "react-router-dom";
import Main from "./Pages/Main";
import SingleChasingShadows from "./Pages/SingleChasingShadows";
import PreSaveChasingShadows from "./Pages/PreSaveChasingShadows";
import PreSaveChasingShadowsSpotify from "./Pages/PreSaveChasingShadowsSpotify";
import PreSaveSerenitySpotify from "./Pages/PreSaveSerenitySpotify";
import SingleSerenity from "./Pages/SingleSerenity";
import PreSaveCarelessSpotify from "./Pages/PreSaveCarelessSpotify";
import SingleCareless from "./Pages/SingleCareless";
import SingleZero from "./Pages/SingleZero";
import Admin from "./Pages/Admin";
import { useState } from "react";
import Login from "./Pages/Login";

function App() {
  const [loggedUser, setLoggedUser] = useState({ logged: false, key: "" })

  return (
    <div className="App">

      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/chasing-shadows" element={<SingleChasingShadows />} />
        <Route path="/chasing-shadows/success" element={<PreSaveChasingShadows success={true} />} />
        <Route path="/serenity" element={<SingleSerenity />} />
        <Route path="/zero" element={<SingleZero />} />
        <Route path="/chasing-shadows/spotify" element={<PreSaveChasingShadowsSpotify success={false} />} />
        <Route path="/chasing-shadows/spotify/success" element={<PreSaveChasingShadowsSpotify success={true} />} />
        <Route path="/presave/careless/success" element={<PreSaveCarelessSpotify success={true} />} />
        <Route path="/presave/careless" element={<PreSaveCarelessSpotify success={false} />} />
        <Route path="/careless" element={<SingleCareless />} />
        <Route path="/serenity/spotify" element={<PreSaveSerenitySpotify success={false} />} />
        <Route path="/serenity/success" element={<PreSaveSerenitySpotify success={true} />} />
        {loggedUser.logged ? <Route path="/hls/admin/aaa" element={<Admin loggedUser={loggedUser} />} /> : <Route path="/hls/admin/aaa" element={<Login setLoggedUser={setLoggedUser} />} />}
        {/* <Route path="/chasing-shadows" element={<PreSaveChasingShadows success={false} />} /> */}
      </Routes>
    </div>
  );
}

export default App;
