import axios from 'axios';
import { getConfig } from '../config/getConfig';
import { useEffect, useState } from 'react';

const config = getConfig();

export default function Admin({ loggedUser }) {
    const [homepageConfig, setHomepageConfig] = useState({ links: [], homepage: { bandLogo: "", backgroundImageURL: "" } });
    const [originalConfig, setOriginalConfig] = useState(null); // To store the original data
    const [newLink, setNewLink] = useState({ new: false, band_name: "Her Last Sight", image: "", link: "", song_name: "", id: "", title: "", type: "single", order: 99 });
    const [deletedLinks, setDeletedLinks] = useState([]);


    async function fetchConfig() {
        const url = `${config.backend}/db/homepageConfig`;
        const resp = await axios.get(url);
        const json = resp.data;
        setHomepageConfig(json);
        setOriginalConfig(json); // Save the original data
    }

    const handleHomepageChange = (field, value) => {
        setHomepageConfig((prevConfig) => ({
            ...prevConfig,
            homepage: {
                ...prevConfig.homepage,
                [field]: { id: field, "url": value }
            },
        }));
    };

    const handleLinkChange = (index, field, value) => {
        const updatedLinks = [...homepageConfig.links];
        updatedLinks[index] = { ...updatedLinks[index], [field]: value };
        setHomepageConfig((prevConfig) => ({ ...prevConfig, links: updatedLinks }));
    };

    const handlePlatformChange = (linkIndex, platform, value) => {
        const updatedLinks = [...homepageConfig.links];
        const updatedPlatforms = { ...updatedLinks[linkIndex].links, [platform]: value };
        updatedLinks[linkIndex].links = updatedPlatforms;
        setHomepageConfig((prevConfig) => ({ ...prevConfig, links: updatedLinks }));
    };

    const handleAddPlatform = (linkIndex) => {
        const platform = prompt('Enter platform name (e.g., spotify, applemusic)');
        const url = prompt(`Enter URL for ${platform}`);
        if (platform && url) {
            handlePlatformChange(linkIndex, platform, url);
        }
    };

    const handleDeleteLink = (index) => {
        setDeletedLinks(prev => ([...prev, homepageConfig.links[index].id]))
        const updatedLinks = [...homepageConfig.links];
        updatedLinks.splice(index, 1); // Remove the link at the given index

        // Recalculate the order for the remaining links
        updatedLinks.forEach((link, i) => {
            link.order = i + 1;
        });

        setHomepageConfig((prevConfig) => ({
            ...prevConfig,
            links: updatedLinks,
        }));
    };

    const handleReorder = (index, direction) => {
        const updatedLinks = [...homepageConfig.links];
        if (direction === 'up' && index > 0) {
            [updatedLinks[index], updatedLinks[index - 1]] = [updatedLinks[index - 1], updatedLinks[index]];
            // Swap the order
            [updatedLinks[index].order, updatedLinks[index - 1].order] = [updatedLinks[index - 1].order, updatedLinks[index].order];
        } else if (direction === 'down' && index < updatedLinks.length - 1) {
            [updatedLinks[index], updatedLinks[index + 1]] = [updatedLinks[index + 1], updatedLinks[index]];
            // Swap the order
            [updatedLinks[index].order, updatedLinks[index + 1].order] = [updatedLinks[index + 1].order, updatedLinks[index].order];
        }
        setHomepageConfig((prevConfig) => ({ ...prevConfig, links: updatedLinks }));
    };

    const handleAddLink = () => {
        newLink.order = homepageConfig.links.length + 1
        if (!newLink.title || !newLink.id) {
            alert("Please fill in the title and id.");
            return;
        }
        setHomepageConfig((prevConfig) => ({
            ...prevConfig,
            links: [...prevConfig.links, newLink],
        }));
        const link = { new: false, band_name: "Her Last Sight", image: "", link: "", song_name: "", id: "", title: "", type: "single", order: 99 }
        setNewLink(link); // Reset new link fields
    };


    const handleNewToggle = (index) => {
        const updatedLinks = [...homepageConfig.links];
        updatedLinks[index].new = !updatedLinks[index].new;
        setHomepageConfig((prevConfig) => ({ ...prevConfig, links: updatedLinks }));
    };

    // Function to detect changes between original and current config
    const getChanges = (original, current) => {
        const changes = { homepage: {}, links: [] };

        // Check for changes in homepage fields
        if (JSON.stringify(original.homepage) !== JSON.stringify(current.homepage)) {
            changes.homepage = current.homepage; // Send the entire homepage object
        }

        // Check for changes in links
        current.links.forEach((link, index) => {
            const originalLink = original.links[index];
            if (JSON.stringify(originalLink) !== JSON.stringify(link)) {
                changes.links[index] = link; // Send the entire link object
            }
        });

        return changes;
    };

    const handleSave = async () => {
        try {
            const changes = getChanges(originalConfig, homepageConfig);
            if (Object.keys(changes.homepage).length === 0 && changes.links.length === 0 && deletedLinks.length === 0) {
                alert("No changes to save.");
                return;
            }
            changes.deletedLinks = deletedLinks
            const url = `${config.backend}/db/insert`;
            await axios.post(url, changes, { headers: { 'hls-ad': loggedUser.key } });
            alert('Data saved successfully!');
        } catch (error) {
            console.error('Error saving data: ' + error.message);
            alert('Failed to save data.');
        }
    };

    const handleApply = async () => {
        try {
            const url = `${config.backend}/db/deploy-firebase`;
            await axios.get(url, { headers: { 'hls-ad': loggedUser.key } });
            alert('Deploy request sent successfully!');
        } catch (error) {
            console.error('Deploy request sent: ' + error.message);
            alert('Failed to Deploy.');
        }
    };

    useEffect(() => {
        fetchConfig();
    }, []);

    return (
        <div style={styles.container}>
            <label>To upload new photos: <a href="https://console.cloudinary.com/pm/c-18fdfa7f82d6c2108c5a2ff43638db/media-explorer?sortBy=createdAt">Cloudinary</a></label>

            <h2 style={styles.heading}>Homepage Configuration</h2>

            <InputField
                label="Background Image URL:"
                value={homepageConfig.homepage.backgroundImageURL.url || ''}
                onChange={(e) => handleHomepageChange('backgroundImageURL', e.target.value)}
            />
            <InputField
                label="Band Logo URL:"
                value={homepageConfig.homepage.bandLogo.url || ''}
                onChange={(e) => handleHomepageChange('bandLogo', e.target.value)}
            />

            <h2 style={styles.heading}>Links</h2>

            <div style={styles.linkCard}>
                <h3 style={styles.heading}>Add New Link</h3>
                <label>(If you want to change type to multi - do it after adding)</label>
                <InputField
                    label="Title:"
                    value={newLink.title}
                    onChange={(e) => setNewLink((prevLink) => ({ ...prevLink, title: e.target.value }))}
                />
                <InputField
                    label="ID:"
                    value={newLink.id}
                    onChange={(e) => setNewLink((prevLink) => ({ ...prevLink, id: e.target.value }))}
                />
                <InputField
                    label="Image URL:"
                    value={newLink.image}
                    onChange={(e) => setNewLink((prevLink) => ({ ...prevLink, image: e.target.value }))}
                />

                {newLink.type === 'single' ? (
                    <InputField
                        label="Link URL:"
                        value={newLink.link}
                        onChange={(e) => setNewLink((prevLink) => ({ ...prevLink, link: e.target.value }))}
                    />
                ) : (
                    <div>
                        <h4 style={styles.subheading}>Platforms:</h4>
                        {Object.keys(newLink.links).map((platform) => (
                            <InputField
                                key={platform}
                                label={`${platform}:`}
                                value={newLink.links[platform] || ''}
                                onChange={(e) => setNewLink((prevLink) => ({
                                    ...prevLink,
                                    links: { ...prevLink.links, [platform]: e.target.value },
                                }))}
                            />
                        ))}
                        <Button
                            text="+ Add Platform"
                            onClick={() => handleAddPlatform(homepageConfig.links.length)}
                            style={styles.addButton}
                        />
                    </div>
                )}

                <Button
                    text="Add Link"
                    onClick={handleAddLink}
                    style={styles.addButton}
                />
            </div>

            {homepageConfig.links.map((link, index) => (
                <LinkCard
                    key={link.id}
                    link={link}
                    index={index}
                    onLinkChange={handleLinkChange}
                    onPlatformChange={handlePlatformChange}
                    onReorder={handleReorder}
                    onNewToggle={handleNewToggle}
                    onAddPlatform={handleAddPlatform}
                    onDeleteLink={handleDeleteLink} // Add delete handler here
                />
            ))}

            <Button
                text="Save Changes"
                onClick={handleSave}
                style={styles.saveButton}
            />
            <Button
                text="Apply Changes"
                onClick={handleApply}
                style={styles.applyButton}
            />
        </div>
    );
}

const styles = {
    container: {
        backgroundColor: 'white',
        padding: '40px',
        maxWidth: '900px',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
    },
    heading: {
        fontSize: '24px',
        marginBottom: '20px',
        color: '#444',
        textAlign: 'center',
    },
    subheading: {
        fontSize: '18px',
        marginBottom: '10px',
        color: '#555',
    },
    field: {
        marginBottom: '15px',
    },
    label: {
        display: 'inline-block',
        width: '150px',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#666',
    },
    input: {
        padding: '8px',
        width: '400px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        fontSize: '14px',
    },
    linkCard: {
        border: '1px solid #ddd',
        borderRadius: '5px',
        padding: '20px',
        marginBottom: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    addButton: {
        padding: '6px 12px',
        backgroundColor: '#28a745',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    },
    reorderButtons: {
        marginTop: '10px',
    },
    reorderButton: {
        padding: '8px 12px',
        marginRight: '5px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    },
    saveButton: {
        display: 'block',
        marginTop: '30px',
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        textAlign: 'center',
        width: '100%',
    },
    applyButton: {
        display: 'block',
        marginTop: '30px',
        padding: '10px 20px',
        backgroundColor: 'green',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        textAlign: 'center',
        width: '100%',
    },
    inputCheckbox: {
        marginLeft: '10px',
        cursor: 'pointer',
    },
    deleteButton: {
        padding: '8px 12px',
        backgroundColor: 'red',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    },
};

const InputField = ({ label, value, onChange, readOnly = false, type = 'text' }) => (
    <div style={styles.field}>
        <label style={styles.label}>{label}</label>
        <input
            type={type}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
            style={styles.input}
        />
    </div>
);

const Button = ({ text, onClick, style }) => (
    <button onClick={onClick} style={style}>
        {text}
    </button>
);

const LinkCard = ({ link, index, onLinkChange, onPlatformChange, onReorder, onNewToggle, onAddPlatform, onDeleteLink }) => (
    <div key={link.id} style={styles.linkCard}>
        <InputField
            label="Title (what people see):"
            value={link.title || ''}
            onChange={(e) => onLinkChange(index, 'title', e.target.value)}
        />
        <InputField
            label="ID: (for storing in db)"
            value={link.id || ''}
            readOnly={true}
        />
        <InputField
            label="song_name: (reports)"
            value={link.song_name || ''}
            readOnly={true}
        />
        <InputField
            label="Image URL:"
            value={link.image || ''}
            onChange={(e) => onLinkChange(index, 'image', e.target.value)}
        />
        <div style={styles.field}>
            <label style={styles.label}>Type:</label>
            <select
                value={link.type}
                onChange={(e) => onLinkChange(index, 'type', e.target.value)}
                style={styles.input}
            >
                <option value="single">Single</option>
                <option value="multi">Multi</option>
            </select>
        </div>
        {link.type === 'single' ? (
            <InputField
                label="Link URL:"
                value={link.link || ''}
                onChange={(e) => onLinkChange(index, 'link', e.target.value)}
            />
        ) : (
            <div>
                <h4 style={styles.subheading}>Platforms:</h4>
                {Object.keys(link.links || {}).map((platform) => (
                    <InputField
                        key={platform}
                        label={`${platform}:`}
                        value={link.links[platform] || ''}
                        onChange={(e) => onPlatformChange(index, platform, e.target.value)}
                    />
                ))}
                <Button
                    text="+ Add Platform"
                    onClick={() => onAddPlatform(index)}
                    style={styles.addButton}
                />
            </div>
        )}
        <InputField
            label="Order:"
            value={link.order || ''}
            readOnly={true}
        />
        <div style={styles.field}>
            <label style={styles.label}>New:</label>
            <input
                type="checkbox"
                checked={link.new || false}
                onChange={() => onNewToggle(index)}
                style={styles.inputCheckbox}
            />
        </div>
        <div style={styles.reorderButtons}>
            <Button
                text="↑"
                onClick={() => onReorder(index, 'up')}
                style={styles.reorderButton}
            />
            <Button
                text="↓"
                onClick={() => onReorder(index, 'down')}
                style={styles.reorderButton}
            />
            <Button
                text="🗑️ Delete"
                onClick={() => onDeleteLink(index)}
                style={styles.deleteButton}
            />
        </div>
    </div>
);